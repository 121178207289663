import http from '@/http/custom-http';
import store from '@/store';

http.interceptors.request.use((config) => {
  const { token } = store.state.auth;
  return {
    ...config,
    headers: {
      ...config.headers,
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  };
}, (err) => Promise.reject(err));

export default http;
