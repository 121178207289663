import http from '@/http/token-http';
import APIFileService from '@/service/APIFileService';

class ProductService extends APIFileService {
  constructor() {
    super('/products');
    this.files = ['image'];
  }

  // eslint-disable-next-line class-methods-use-this
  buildFormData(product) {
    const formData = new FormData();
    formData.append('name', product.name);
    formData.append('image', product.image);
    formData.append('description', product.description);
    formData.append('price', product.price);
    formData.append('stock', Number(product.stock).toString());
    formData.append('minimum_stock', Number(product.minimum_stock).toString());
    if (product.release_date) {
      formData.append('release_date', product.release_date);
    }
    formData.append('is_orderable', Number(product.is_orderable).toString());
    formData.append('category_id', product.category_id);
    formData.append('game_id', product.game_id);
    formData.append('language_id', product.language_id);
    return formData;
  }

  searchAndFilter(nameTerm = '', orderProducts = []) {
    const name = nameTerm.toLowerCase();
    return http.get(this.resource, { params: { name, page: 1 } })
      .then((res) => res.data.data)
      .then((products) => {
        if (orderProducts.length) {
          return products.filter((product) => !orderProducts
            .find((orderProduct) => orderProduct.id === product.id));
        }
        return products;
      });
  }

  // eslint-disable-next-line class-methods-use-this
  getLowSupply(page = 1) {
    return http.get('/products-low-supply', { params: { page } })
      .then((res) => res.data);
  }
}

export default new ProductService();
