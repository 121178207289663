import http from '@/http/token-http';

export default class APIService {
  constructor(resource) {
    this.resource = resource;
    if (this.constructor === APIService) {
      throw new Error('APIService is abstract and cannot be instantiated!');
    }
  }

  indexByFilter(filter, page = null) {
    const params = { ...filter, page };
    return this.index({ params });
  }

  index(config) {
    return http.get(this.resource, config)
      .then((res) => res.data);
  }

  indexPaginated(page = 1) {
    return this.index({ params: { page } });
  }

  create(data, config) {
    return http.post(this.resource, data, config)
      .then((res) => res.data);
  }

  update(id, data, config) {
    return http.put(`${this.resource}/${id}`, data, config)
      .then((res) => res.data);
  }

  delete(id) {
    return http.delete(`${this.resource}/${id}`);
  }
}
