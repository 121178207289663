import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import authModule from '@/store/auth-module';
import modalModule from '@/store/modal-module';
import stockModule from './stock-module';

Vue.use(Vuex);

const persistAuth = new VuexPersistence({
  modules: ['auth'],
});

export default new Vuex.Store({
  modules: {
    auth: authModule,
    modal: modalModule,
    stock: stockModule,
  },
  plugins: [persistAuth.plugin],
});
