<template>
  <div class="md-tabs-navigation" v-if="isAlertEnable">
    <md-badge :md-content="quantityProducts">
      <md-button @click="openStock">Sous-Stock</md-button>
    </md-badge>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductService from '@/components/product/ProductService';

export default {
  name: 'StockAlert',
  data: () => ({
    timer: null,
  }),
  computed: {
    ...mapGetters(['isAlertEnable', 'quantityProducts']),
  },
  methods: {
    openStock() {
      this.$router.push('stock');
    },
    async checkStock() {
      try {
        const { total } = await ProductService.getLowSupply();
        await this.$store.dispatch('setQuantity', total);
      } catch (err) {
        this.handleError(err);
      }
    },
  },
  mounted() {
    this.checkStock();
    this.timer = setInterval(() => this.checkStock(), 30000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
