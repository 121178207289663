import http from '@/http/custom-http';

export default class AuthService {
  static login(user) {
    return http.post('/login-backoffice', user)
      .then((res) => ({
        token: res.data.access_token,
        user: res.data.user,
      }))
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          return null;
        }
        throw err;
      });
  }

  static sendResetLink(email) {
    return http.post('/forgot-password', { email });
  }
}
