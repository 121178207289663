<template>
  <back-office/>
</template>

<script>

import BackOffice from '@/components/shared/back-office/BackOffce.vue';

export default {
  components: { BackOffice },
};
</script>
