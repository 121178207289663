export default {
  state: {
    token: null,
    user: {},
  },

  mutations: {
    LOG_IN: (state, { token, user }) => {
      state.token = token;
      state.user = user;
    },
    LOG_OUT: (state) => {
      state.token = null;
      state.user = {};
    },
  },

  getters: {
    isLoggedIn: (state) => !!(state.token),
  },

  actions: {
    // eslint-disable-next-line camelcase
    login({ commit }, { token, user }) {
      commit('LOG_IN', { token, user });
    },
    logout({ commit }) {
      commit('LOG_OUT');
    },
  },
};
