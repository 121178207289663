export default {
  state: {
    isVisible: false,
    object: {},
    categories: [],
    languages: [],
    games: [],
    memberships: [],
    users: [],
  },

  mutations: {
    SHOW_MODAL: (state) => {
      state.isVisible = true;
    },
    HIDE_MODAL: (state) => {
      state.isVisible = false;
      state.object = {};
    },
    SET_OBJECT: (state, object) => {
      state.object = object;
    },
    SET_CATEGORIES: (state, categories) => {
      state.categories = categories;
    },
    SET_LANGUAGES: (state, languages) => {
      state.languages = languages;
    },
    SET_GAMES: (state, games) => {
      state.games = games;
    },
    SET_MEMBERSHIPS: (state, memberships) => {
      state.memberships = memberships;
    },
    SET_USERS: (state, users) => {
      state.users = users;
    },
  },

  getters: {
    isModalVisible: (state) => state.isVisible,
    object: (state) => state.object,
    categories: (state) => state.categories,
    languages: (state) => state.languages,
    games: (state) => state.games,
    memberships: (state) => state.memberships,
    getFirstCategoryId: (state) => (state.categories.length ? state.categories[0].id : 0),
    getFirstLanguageId: (state) => (state.languages.length ? state.languages[0].id : 0),
    getFirstGameId: (state) => (state.games.length ? state.games[0].id : 0),
    getFirstMembershipId: (state) => (state.memberships.length ? state.memberships[0].id : 0),
    getFirstUserId: (state) => (state.users.length ? state.users[0].id : 0),
  },

  actions: {
    setObject({ commit }, object) {
      commit('SET_OBJECT', object);
    },
    openModal({ commit }, object = {}) {
      commit('SHOW_MODAL');
      commit('SET_OBJECT', object);
    },
    closeModal({ commit }) {
      commit('HIDE_MODAL');
    },
    addCategories({ commit }, categories) {
      commit('SET_CATEGORIES', categories);
    },
    addLanguages({ commit }, languages) {
      commit('SET_LANGUAGES', languages);
    },
    addGames({ commit }, games) {
      commit('SET_GAMES', games);
    },
    addMemberships({ commit }, memberships) {
      commit('SET_MEMBERSHIPS', memberships);
    },
    addUsers({ commit }, users) {
      commit('SET_USERS', users);
    },
  },
};
