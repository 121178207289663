import Vue from 'vue';
import App from '@/App.vue';
import VueToast from 'vue-toast-notification';
import { Datetime } from 'vue-datetime';

import {
  MdApp,
  MdAutocomplete,
  MdAvatar,
  MdBadge,
  MdButton,
  MdCard,
  MdContent,
  MdDatepicker,
  MdDialog,
  MdDialogConfirm,
  MdDivider,
  MdDrawer,
  MdElevation,
  MdField,
  MdIcon,
  MdLayout,
  MdList,
  MdMenu,
  MdProgress,
  MdRipple,
  MdSwitch,
  MdTabs,
  MdToolbar,
  MdTooltip,
} from '@fivaz/vue-material/dist/components';

import store from './store';
import router from './router';

import '@fivaz/vue-material/dist/vue-material.min.css';
import '@fivaz/vue-material/dist/theme/default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'vue-datetime/dist/vue-datetime.css';

import '@/assets/css/auth.css';
import '@/assets/css/build.css';
import '@/assets/css/form.css';
import '@/assets/css/item.css';
import '@/assets/css/page.css';
import '@/assets/css/pagination.css';

Vue.use(MdApp);
Vue.use(MdAutocomplete);
Vue.use(MdAvatar);
Vue.use(MdBadge);
Vue.use(MdButton);
Vue.use(MdCard);
Vue.use(MdContent);
Vue.use(MdDatepicker);
Vue.use(MdDialog);
Vue.use(MdDialogConfirm);
Vue.use(MdDivider);
Vue.use(MdDrawer);
Vue.use(MdElevation);
Vue.use(MdField);
Vue.use(MdIcon);
Vue.use(MdLayout);
Vue.use(MdList);
Vue.use(MdMenu);
Vue.use(MdProgress);
Vue.use(MdRipple);
Vue.use(MdSwitch);
Vue.use(MdTabs);
Vue.use(MdToolbar);
Vue.use(MdTooltip);

Vue.use(VueToast);
Vue.use(Datetime);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
