import LogoutService from '@/components/auth/LogoutService';

export default {
  methods: {
    async logout() {
      try {
        await LogoutService.logout();
      } catch (err) {
        this.handleError(err);
      }
      await this.$store.dispatch('logout');
      await this.$router.push('login');
    },
  },
};
