<template>
  <div class="page-container">
    <md-app class="full-height" md-mode="fixed">
      <md-app-toolbar class="md-primary site-color" md-elevation="2">
        <md-button v-if="isLoggedIn" class="drawer-button md-icon-button"
                   @click="showNavigation = true">
          <md-icon>menu</md-icon>
        </md-button>

        <h3 class="md-title flex-grow">{{ titleApp }}</h3>

        <div class="nav-bar">
          <nav-bar v-if="isLoggedIn"/>
        </div>
      </md-app-toolbar>

      <md-app-drawer class="drawer-size" v-if="isLoggedIn" :md-active.sync="showNavigation"
                     md-swipeable>
        <md-toolbar class="md-transparent" md-elevation="0">
          <span class="md-title">{{ titleApp }}</span>
        </md-toolbar>

        <md-list @click="showNavigation = false">
          <md-list-item v-for="route of routes" :key="route.path" :to="route.path">
            <md-icon>{{ route.icon }}</md-icon>
            <span class="md-list-item-text">{{ route.name }}</span>
          </md-list-item>

          <md-list-item v-if="isAlertEnable" to="/stock">
            <md-icon class="md-accent">trending_down</md-icon>
            <span class="md-list-item-text">Stock</span>
          </md-list-item>

          <md-list-item @click="logout">
            <md-icon>logout</md-icon>
            <span class="logout-button md-list-item-text">Déconnexion</span>
          </md-list-item>
        </md-list>
      </md-app-drawer>

      <md-app-content>
        <router-view ref="child"/>
      </md-app-content>
    </md-app>

    <md-button v-if="showFab" @click="open" class="md-fab md-primary site-color md-fab-bottom-right md-fixed">
      <md-icon>add</md-icon>
    </md-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { routes } from '@/router';
import NavBar from '@/components/shared/nav-bar/NavBar.vue';
import authMixin from '@/mixins/authentication-mixin';

export default {
  name: 'BackOffice',
  data: () => ({
    showNavigation: false,
    titleApp: 'L\'Arène de Duel',
    routes: routes.filter((route) => !route.hidden),
  }),
  mixins: [authMixin],
  components: {
    NavBar,
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'isAlertEnable']),
    showFab() {
      return !this.$route.meta.hideFab;
    },
  },
  methods: {
    open() {
      this.$store.dispatch('openModal');
    },
    hideModal() {
      this.$store.dispatch('closeModal');
    },
  },
  mounted() {
    window.onpopstate = () => {
      this.hideModal();
    };
  },
  beforeDestroy() {
    window.onpopstate = () => {
      this.hideModal();
    };
  },
};
</script>

<style scoped>

.flex-grow {
  flex-grow: 1;
}

.drawer-size {
  width: 230px;
  max-width: calc(100vw - 125px);
}

@media only screen and (min-width: 1140px) {
  .drawer-button {
    display: none;
  }
}

@media only screen and (max-width: 1140px) {
  .nav-bar {
    display: none;
  }
}

.logout-button {
  text-align: initial;
}

.full-height {
  height: 100vh;
}
</style>
