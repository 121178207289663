export default {
  methods: {
    showMessage(message, type = 'success', duration = 1000) {
      this.$toast.open({
        position: 'top',
        message,
        type,
        duration,
      });
    },
    handleError(err) {
      if (err.response) {
        this.handleAPIError(err.response);
      } else {
        this.showError(err);
      }
    },
    handleAPIError(err) {
      if (err.status === 401) {
        this.$store.commit('LOG_OUT');
        this.$router.push('login');
        this.showError(err.data.message);
      } else if (err.data.errors) {
        this.handleFormValidation(err.data.errors);
      } else if (err.data.message) {
        this.showError(err.data.message);
      } else {
        this.showError(err.data);
      }
    },
    handleFormValidation(errors) {
      const [errorField] = Object.keys(errors);
      const [firstError] = errors[errorField];
      this.showError(firstError);
    },
    showError(message) {
      this.showMessage(message, 'error', 3000);
    },
    showSuccess(message) {
      this.showMessage(message);
    },
  },
};
