import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import LoginPage from '@/components/auth/LoginPage.vue';

Vue.use(VueRouter);

export const routes = [
  {
    path: '*',
    redirect: '/products',
    hidden: true,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    hidden: true,
    meta: {
      public: true,
      hideFab: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'Mot de passe oublié',
    component: () => import(/*webpackChunkName: "ForgotPasswordPage"*/'@/components/auth/ForgotPasswordPage.vue'),
    hidden: true,
    meta: {
      public: true,
      hideFab: true,
    },
  },
  {
    path: '/products',
    name: 'Produits',
    icon: 'shopping_cart',
    component: () => import(/*webpackChunkName: "ProductsPage"*/'@/components/product/ProductsPage.vue'),
  },
  {
    path: '/orders',
    name: 'Commandes',
    icon: 'attach_money',
    component: () => import(/*webpackChunkName: "OrdersPage"*/'@/components/order/OrdersPage.vue'),
  },
  {
    path: '/events',
    name: 'Événements',
    icon: 'event',
    component: () => import(/*webpackChunkName: "EventsPage"*/'@/components/event/EventsPage.vue'),
  },
  {
    path: '/users',
    name: 'Utilisateurs',
    icon: 'people',
    component: () => import(/*webpackChunkName: "UsersPage"*/'@/components/user/UsersPage.vue'),
  },
  {
    path: '/members',
    name: 'Membres',
    icon: 'verified',
    component: () => import(/*webpackChunkName: "MembersPage"*/'@/components/member/MembersPage.vue'),
  },
  {
    path: '/memberships',
    name: 'Abonnements',
    icon: 'card_membership',
    component: () => import(/*webpackChunkName: "MembershipsPage"*/'@/components/membership/MembershipsPage.vue'),
  },
  {
    path: '/categories',
    name: 'Catégories',
    icon: 'local_offer',
    component: () => import(/*webpackChunkName: "CategoriesPage"*/'@/components/category/CategoriesPage.vue'),
  },
  {
    path: '/games',
    name: 'Jeux',
    icon: 'sports_esports',
    component: () => import(/*webpackChunkName: "GamesPage"*/'@/components/game/GamesPage.vue'),
  },
  {
    path: '/languages',
    name: 'Langues',
    icon: 'translate',
    component: () => import(/*webpackChunkName: "LanguagesPage"*/'@/components/language/LanguagesPage.vue'),
  },
  {
    path: '/stock',
    name: 'Stock',
    component: () => import(/*webpackChunkName: "StockPage"*/'@/components/product/stock/StockPage.vue'),
    hidden: true,
    meta: {
      hideFab: true,
    },
  },
  {
    path: '/info',
    name: 'Informations',
    icon: 'store',
    component: () => import(/*webpackChunkName: "InfoPage"*/'@/components/info/InfoPage.vue'),
    meta: {
      hideFab: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((routeTo, routeFrom, next) => {
  if (!routeTo.meta.public && !store.getters.isLoggedIn) {
    return next('login');
  }
  if (routeTo.path === '/login' && store.getters.isLoggedIn) {
    return next('products');
  }
  return next();
});

export default router;
