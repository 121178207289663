export default {
  state: {
    nbProducts: 0,
  },
  getters: {
    quantityProducts: (state) => state.nbProducts,
    isAlertEnable: (state) => state.nbProducts !== 0,
  },
  mutations: {
    SET_QUANTITY: (state, quantity) => {
      state.nbProducts = quantity;
    },
    DECREASE_QUANTITY: (state) => {
      state.nbProducts -= 1;
    },
  },
  actions: {
    setQuantity({ commit }, quantity) {
      commit('SET_QUANTITY', quantity);
    },
    decreaseQuantity({ commit }) {
      commit('DECREASE_QUANTITY');
    },
  },
};
