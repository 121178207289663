<template>
  <div class="centered-container">

    <md-content class="md-elevation-3">

      <div class="title">
        <img src="@/assets/logo-arene.png" alt="logo">
        <div class="md-title">Connexion</div>
      </div>

      <validator v-slot="{ handleSubmit }">
        <form novalidate @submit.prevent="handleSubmit(auth)">
          <div class="form">
            <rule name="e-mail" rules="required|email" v-slot="{errors}">
              <md-field>
                <label>E-mail</label>
                <md-input v-model="credentials.email"></md-input>
                <span class="input-error">{{ errors[0] }}</span>
              </md-field>
            </rule>

            <rule name="mot de passe" rules="required" v-slot="{errors}">
              <md-field>
                <label>Mot de passe</label>
                <md-input type="password" v-model="credentials.password"></md-input>
                <span class="input-error">{{ errors[0] }}</span>
              </md-field>
            </rule>
          </div>

          <div class="actions md-layout md-alignment-center-space-between">
            <router-link class="link" to="forgot-password">
              Mot de passe oublié?
            </router-link>
            <md-button type="submit" class="md-raised md-primary site-color">
              Se connecter
            </md-button>
          </div>
        </form>
      </validator>

      <div class="loading-overlay" v-if="loading">
        <md-progress-spinner md-mode="indeterminate" :md-stroke="2"></md-progress-spinner>
      </div>

    </md-content>
    <div class="background"/>
  </div>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/fr.json';
import notificationMixin from '@/mixins/notification-mixin';
import AuthService from '@/components/auth/AuthService';

extend('email', {
  ...email,
  message: messages.email,
});

extend('required', {
  ...required,
  message: messages.required,
});

export default {
  name: 'LoginForm',
  data: () => ({
    loading: false,
    credentials: {
      email: '',
      password: '',
    },
  }),
  components: {
    rule: ValidationProvider,
    validator: ValidationObserver,
  },
  mixins: [notificationMixin],
  methods: {
    async auth() {
      this.loading = true;
      try {
        const data = await AuthService.login(this.credentials);
        if (data) {
          await this.$store.dispatch('login', data);
          this.showSuccess('Utilisateur connecté');
          await this.$router.push('products');
        } else {
          this.showError('Utilisateur ou mot de passe erroné');
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
