<template>
  <div class="md-layout md-alignment-center">
    <md-tabs md-sync-route class="md-primary site-color" md-alignment="right">
      <md-tab md-label="produits" to="/products"></md-tab>
      <md-tab md-label="commandes" to="/orders"></md-tab>
      <md-tab md-label="événements" to="/events"></md-tab>
      <md-tab md-label="utilisateurs" to="/users"></md-tab>
      <md-tab md-label="membres" to="/members"></md-tab>
    </md-tabs>

    <md-menu class="md-tabs-navigation" md-direction="bottom-start">
      <md-button md-menu-trigger>autres</md-button>
      <md-menu-content>
        <md-menu-item to="/memberships">ABONNEMENTS</md-menu-item>
        <md-menu-item to="/categories">CATÉGORIES</md-menu-item>
        <md-menu-item to="/games">JEUX</md-menu-item>
        <md-menu-item to="/languages">LANGUES</md-menu-item>
        <md-menu-item to="/info">INFORMATIONS</md-menu-item>
      </md-menu-content>
    </md-menu>

    <stock-alert/>

    <div class="md-toolbar-section-end">
      <md-button @click="logout" class="md-icon-button">
        <md-icon>logout</md-icon>
      </md-button>
    </div>
  </div>
</template>

<script>

import authMixin from '@/mixins/authentication-mixin';
import StockAlert from '@/components/shared/stock-alert/StockAlert.vue';
import notificationMixin from '@/mixins/notification-mixin';

export default {
  name: 'NavBar',
  mixins: [authMixin, notificationMixin],
  components: {
    StockAlert,
  },
};
</script>

<style>

.md-toolbar-section-end {
  margin-left: 20px;
}

</style>
