import http from '@/http/token-http';
import APIService from '@/service/APIService';

export default class APIFileService extends APIService {
  constructor(resource) {
    super(resource);
    if (this.constructor === APIFileService) {
      throw new Error('APIFileService is abstract and cannot be instantiated!');
    }
  }

  static preventImageCache(object) {
    const model = object;
    if (model.image_path) {
      model.image_path = `${object.image_path}?anti-cache=${Date.now()}`;
    }
    return model;
  }

  // eslint-disable-next-line class-methods-use-this
  buildFormData(model) {
    return model;
  }

  hasFiles(model) {
    return this.files.some((file) => !!model[file]);
  }

  create(model) {
    if (this.hasFiles(model)) {
      return this.createWithFile(model);
    }
    return super.create(model);
  }

  update(id, model) {
    if (this.hasFiles(model)) {
      return this.updateWithFile(id, model);
    }
    return super.update(id, model);
  }

  createWithFile(model) {
    const object = this.buildFormData(model);
    return http.post(this.resource, object, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((res) => res.data)
      .then((object) => APIFileService.preventImageCache(object));
  }

  updateWithFile(id, model) {
    const object = this.buildFormData(model);
    return http.post(`${this.resource}/${id}`, object, {
      headers: { 'Content-Type': 'multipart/form-data' },
      params: { _method: 'PUT' },
    })
      .then((res) => res.data)
      .then((object) => APIFileService.preventImageCache(object));
  }
}
