import axios from 'axios';

const http = axios.create({
  baseURL: `${process.env.VUE_APP_API}/api`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default http;
